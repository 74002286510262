<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div *ngIf="!hideBecauseOne">
    <div *ngIf="apiTarget !== 'search' && apiTarget !== 'searchObject' && dataType !== 'tile'">
      <mat-form-field>
        <input [style.visibility]="'hidden'" matInput name="{{itemName}}" [(ngModel)]="value" [required]="required"
          [errorStateMatcher]="esMatcher">
        <mat-label>{{placeholder | translate}}</mat-label>
        <mat-select ng-model="itemName" [required]="required" (selectionChange)="setAttribute($event)" [value]="value"
          [disabled]="conditionalDisable">
          <mat-option value="">Select {{placeholder | translate}}</mat-option>
          <mat-option *ngFor="let itm of selectData" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="required && (value === undefined || value === '')">
          {{placeholder | translate}} is required.
        </mat-error>
      </mat-form-field>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
    <div *ngIf="apiTarget === 'search' || apiTarget === 'searchObject'" class="searchClass">
      <ng-select class="custom" ng-model="itemName" name="{{itemName}}" [items]="filteredData | async" bindLabel="text"
        autofocus bindValue="_id" [loading]="loading" [clearable]="clearable" (search)="termSearch($event)"
        (clear)="termSearch('')" (change)="setAttribute($event)" [(ngModel)]="value" [disabled]="conditionalDisable"
        placeholder="{{placeholder | translate}}">
      </ng-select>
      <div class="my-error mat-error mat-form-field-subscript-wrapper"
        *ngIf="required && (value === undefined || value.length === 0)">
        {{placeholder | translate}} is required.
      </div>
    </div>
    <div *ngIf="apiTarget === 'summary' && dataType == 'tile'" class="searchClass">
      <mat-form-field class="example-full-width">
        <mat-label>{{placeholder | translate}}</mat-label>
        <input type="text" [value]="dataText" matInput [formControl]="stateCtrl" [matAutocomplete]="auto"
          [required]="required" (input)="termSearchTile($event.target.value)"
          (blur)="onBlurMethod($event.target.value)">
        <button *ngIf="dataText" matSuffix mat-icon-button aria-label="Clear" (click)="clearData()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)="setAttribute($event.option.value)" (closed)="closed()" (opened)="opened()">
          <mat-option *ngFor="let itm of filteredData | async" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>