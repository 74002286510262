import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  noButton: string;
  yesButton: string;
  cancelButton: string;
  agreeRadio: boolean = false;
  agreeRadioForm: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.noButton = data.noButton || 'No';
    this.yesButton = data.yesButton || 'Yes';
    this.cancelButton = data.cancelButton;
    this.agreeRadio = data.agreeRadio || false;
  }

  onConfirm(): void {
    if (this.agreeRadio) {
      if (this.agreeRadioForm === 'agree') {
        this.dialogRef.close(true);
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onCancel(): void {
    this.dialogRef.close(undefined);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public noButton: string,
    public yesButton: string,
    public cancelButton: string,
    public agreeRadio: boolean
  ) { }
}
