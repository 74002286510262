import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-custom-basechart',
  templateUrl: './custom-basechart.component.html',
  styleUrls: ['./custom-basechart.component.scss']
})
export class CustomBasechartComponent implements OnInit {
  public enumListObj: any = {};
  @Input() chartData: any;
  @Input() chartType: string = 'bar';
  @Input() chartName: string = 'chart';
  @Input() chartHeight: string = '70vh';
  @Input() chartOptions: any;
  @Input() enableDownload: boolean = false;
  @Input() loading: boolean = false;
  @Output() downloadData = new EventEmitter<any>();
  @ViewChild(BaseChartDirective) chartDirective!: BaseChartDirective;
  constructor() {
  }
  ngOnInit() { }

  downloadImgMethod() {
    const chart = this.chartDirective.chart; // Access the Chart.js instance
    if (chart) {
      const link = document.createElement('a');
      link.href = chart.toBase64Image();
      link.download = this.chartName + '.png';
      link.click();
    }
  }
  downloadDataMethod(type) {
    this.downloadData.emit({ type: type, chartName: this.chartName });
  }
}
