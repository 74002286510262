import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle, ApexResponsive, ApexTooltip, ApexPlotOptions } from 'ngx-apexcharts';

@Component({
  selector: 'app-custom-apexchart',
  templateUrl: './custom-apexchart.component.html',
  styleUrls: ['./custom-apexchart.component.scss']
})
export class CustomApexchartComponent implements OnInit {
  public chartSeries: ApexAxisChartSeries = [];
  @Input() colors = [
    'rgba(54, 162, 235, 0.9)',
    'rgba(255, 99, 132, 0.9)',
    'rgba(255, 159, 64, 0.9)',
    'rgba(255, 206, 86, 0.9)',
    'rgba(75, 192, 192, 0.9)',
    'rgba(153, 102, 255, 0.98)'
  ];
  public labels = [];
  public chartName: string = 'chart';
  @Input() chartHeight: string = '70vh';
  @Input() loading: boolean = false;

  public _chartOptions: any = undefined;
  @Input()
  set chartOptions(chartOptions: any) {
    this._chartOptions = chartOptions;
    this.chartDetails.toolbar.show = chartOptions.enableDownload || false;
    this.chartDetails.type = chartOptions.type || 'bar';
    this.chartDetails.stacked = chartOptions.stacked || true;
    this.chartDetails.toolbar.export.csv.filename = chartOptions.title;
    this.chartDetails.toolbar.export.svg.filename = chartOptions.title;
    this.chartDetails.toolbar.export.png.filename = chartOptions.title;
    this.title.text = chartOptions.title;
    this.chartName = chartOptions.uniqueName;
    this.plotOptions = chartOptions.plotOptions || {};
  }
  get chartOptions(): any {
    return this._chartOptions;
  }
  public _chartData: any = undefined;
  @Input()
  set chartData(chartData: any) {
    this._chartData = chartData;
    this.chartSeries = chartData.datasets;
    this.labels = chartData.labels;
    this.xaxis.categories = chartData.labels;
  }
  get chartData(): any {
    return this._chartData;
  }
  public makeSmallText(labels, maxLength = 30) {
    return labels.map(str => str.length > maxLength ? str.slice(0, maxLength) + '...' : str);
  }
  public chartDetails: ApexChart = {
    type: 'bar',
    stacked: true,
    height: 350,
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: 'custom-filename-csv', // CSV export filename
        },
        svg: {
          filename: 'custom-filename-svg', // SVG export filename
        },
        png: {
          filename: 'custom-filename-png', // PNG export filename
        }
      }
    }
  };
  xaxis: ApexXAxis = {
    categories: [],
    labels: {
      formatter: (val: string): string => {
        // Truncate the label and add ellipsis
        return val.length > 10 ? val.substring(0, 10) + '...' : val;
      }
    }
  };
  tooltip: ApexTooltip = {
    x: {
      formatter: (val, opts) => {
        return opts.w.globals.labels[opts.dataPointIndex]; // Show full label in tooltip
      }
    }
  };

  plotOptions: ApexPlotOptions = {}
  dataLabels: ApexDataLabels = {
    enabled: false
  };
  title: ApexTitleSubtitle = {
    text: 'Chart',
    align: 'center'
  };
  // Responsive settings
  responsive: ApexResponsive[] = [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'top'
        }
      }
    }
  ];
  @Output() downloadData = new EventEmitter<any>();
  // @ViewChild(BaseChartDirective) chartDirective!: BaseChartDirective;
  constructor() {
  }
  ngOnInit() { }

  downloadImgMethod() {
    // const chart = this.chartDirective.chart; // Access the Chart.js instance
    // if (chart) {
    //   const link = document.createElement('a');
    //   link.href = chart.toBase64Image();
    //   link.download = this.chartName + '.png';
    //   link.click();
    // }
  }
  downloadDataMethod(type) {
    this.downloadData.emit({ type: type, chartName: this.chartName });
  }
}
