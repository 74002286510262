<div *ngIf="!loading" fxFlex fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center center">
    <div class="parentGraph" fxFlexFill fxGrow="0" fxLayoutAlign="center center">
        <ng-container *ngIf="enableDownload">
            <button class="downloadImg" mat-icon-button [matMenuTriggerFor]="chartMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #chartMenu="matMenu">
                <button mat-menu-item (click)="downloadImgMethod()">
                    <span>{{'Download as PNG' | translate}}</span>
                </button>
                <button mat-menu-item (click)="downloadDataMethod('csv')">
                    <span>{{'Download as CSV' | translate}}</span>
                </button>
            </mat-menu>
        </ng-container>
        <canvas baseChart class="chart" [data]="chartData" [type]="chartType" [options]="chartOptions">
        </canvas>
    </div>
</div>