<form class="customAutocomplete example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput type="text" placeholder="{{placeholder}}" [matAutocomplete]="auto"
      (input)="termSearch($event.target.value)" style="width: 100%;" (blur)="onBlurMethod($event.target.value)"
      [formControl]="stateCtrl">
    <mat-autocomplete class="autocomplete" #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="setAttribute($event.option.value)">
      <mat-option *ngIf="addAll" [value]="{_id: 'all', text: 'All'}" title="{{'All'}}"
        [class.selectedItemList]="selectedData && selectedData._id == 'all'">
        {{'All'}}
        <mat-icon *ngIf="selectedData && selectedData._id == 'all'">check</mat-icon>
      </mat-option>
      <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text}}"
        [class.selectedItemList]="selectedData && selectedData._id == option._id">
        {{option.text}}
        <mat-icon *ngIf="selectedData && selectedData._id == option._id">check</mat-icon>
        <!-- <i *ngIf="canDelete" class="material-icons deleteItem" (click)="deleteItem($event, option)">cancel</i> -->
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>