import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { EditProfileDialogComponent } from '../components/modals/edit-profile-dialog/edit-profile-dialog.component';
import { AuthenticationService } from '../services/auth.service';
import { LayoutUtilsService } from '../services/layout-utils.service';
import { RequestService } from '../services/request.service';
import { SpinnerService } from '../services/spinner.service';
import moment from 'moment';
import { FirebaseHandlersService } from '../services/firebase-handlers.service';
import { NotificationService } from '../services/notification.service';
import { PermissionsService } from '../services';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  userName: string = '';
  permissions: any = undefined;
  isAdmin: boolean = false;
  isSupervisor: boolean = false;
  isCaseManager: boolean = false;
  isSuperAdmin: boolean = false;
  currentRoute: string = '';
  clients: any = [];
  public now = new Date();
  pictureLink: string = '';
  private subscriptions: any[] = <any>[];
  // private autoLogoutSubscription: Subscription = new Subscription;
  currentUser: any = undefined;
  currentNotification: any = undefined;
  notificationTimer: any = undefined;
  showNotificationUnRead: boolean = false;
  showConfSubSubMenu: boolean = false;
  notificationAudio: any = undefined;
  isDnd: boolean = true;

  @ViewChild('snav') snav: MatSidenav;
  @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref)
      this.notificationAudio = ref.nativeElement;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher, private firebaseHandler: FirebaseHandlersService,
    public spinnerService: SpinnerService, private notificationService: NotificationService,
    private authService: AuthenticationService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService, private activatedRoute: ActivatedRoute,
    private requestService: RequestService, private permissionsService: PermissionsService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd ||
        data instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
        // console.log(activatedRoute.snapshot['_routerState'].url)
        if (this.currentRoute == '/tiles' || this.currentRoute == '/score-reports' || this.currentRoute == '/rules' || this.currentRoute == '/evaluation' || this.currentRoute == '/procedures') {
          this.showConfSubSubMenu = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((user) => {
        if (user) {
          this.currentUser = user;
          // this.isDnd = user.dnd;

          if (!user) {
            setTimeout(() => {
              this.requestService.logout();
            }, 200);
          } else {
            this.subscriptions.push(
              this.authService.userImageSubject.subscribe((img) => {
                if (img) this.pictureLink = img;
              })
            );

            let currentUserRole = this.requestService.getRoleByUserData(user);
            this.isAdmin = currentUserRole === environment.customKeys.roleAdmin;
            this.isSupervisor = currentUserRole === environment.customKeys.roleSupervisor;
            this.isCaseManager = currentUserRole === environment.customKeys.roleCaseManager;
            this.permissions = this.permissionsService.getRoleSectionOperationPermissions(currentUserRole, 'pages', 'show');
            this.isSuperAdmin = user.isSuperAdmin;
            this.userName = user.name;
            this.pictureLink = user.pictureLink || 'assets/images/profile.png';

            this.requestService.getDataList(
              'user',
              {
                page: 1,
                term: '',
                filter: {
                  $and: [
                    {
                      'resources._id': {
                        $eq: environment.customKeys.roleAdmin,
                      },
                    },
                    { _id: { $ne: user._id } },
                  ],
                },
                orderBy: 'name',
                orderDir: 'asc',
                fieldKeys: ['email', 'name'],
              },
              (data, error) => {
                if (data) {
                  this.clients = data.results;
                } else if (error) {
                  this.layoutUtilsService.showNotification(
                    this.translate.instant('An error has occurred. Please try again later.'),
                    this.translate.instant('Dismiss')
                  );
                }
                // this.loadService.display(false);
              }
            );
          }
        }
      })
    );

    this.subscriptions.push(this.notificationService.dnd.subscribe(async (isDnd) => {
      this.isDnd = isDnd;
    }));

    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show)
        this.showNotificationUnRead = show;
    }));

    this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe((message: any) => {
      // console.log('message', message)
      if (message && message.notification) {
        this.notificationService.showNotificationUnRead.next(true);
        this.notificationService.refreshData.next(true);

        if (!this.isDnd) {
          if (this.notificationAudio) {
            this.notificationAudio.load();
            this.notificationAudio.loop = 0;
            this.notificationAudio.volume = 0.25;
            this.notificationAudio.play().catch((error: any) => { });
          }

          this.currentNotification = message.notification.body;
          if (this.notificationTimer) {
            clearTimeout(this.notificationTimer);
            this.notificationTimer = undefined;
          }
          this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
        }
      }
      else
        this.currentNotification = null;
    }));

    window.addEventListener('visibilitychange', this.visibilitychange.bind(this), false);

    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // this.autoLogoutSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  logout() {
    this.spinnerService.show();
    this.firebaseHandler.logout();
  }

  public visibilitychange = () => {
    if (document.hidden) {
      // console.log('User left window');
    } else {
      if (!this.requestService.validateMe()) {
        window.location.reload();
      }
    }
  }

  openEditProfile() {
    const dialog = this.dialog.open(EditProfileDialogComponent, {
      width: '50vw',
      // height: '80vh',
      disableClose: true,
    });
    dialog.afterClosed().subscribe((value) => { });
  }

  private getNotifications() {
    this.notificationService.getNotifications('', 0, 1).then((data: any) => {
      if (localStorage.getItem('last-seen-notifications')) {
        let lastSeen = moment(Number(localStorage.getItem('last-seen-notifications'))).utc();
        if (lastSeen && lastSeen.isBefore(moment(data.results[0]?.createdAt))) {
          this.showNotificationUnRead = true;
        }
      }
    }).catch((error: any) => {

    }).finally(() => {

    });
  }

  closeNotification() {
    this.currentNotification = null;
  }

  gotoNotifications() {
    this.router.navigate(['/notifications']);
  }

  onMenuItemClick(showConfSubSubMenu): void {
    this.showConfSubSubMenu = showConfSubSubMenu;
    if (this.mobileQuery.matches) {
      this.snav.close();
    }
  }

  onSubMenuItemClick() {
    if (this.mobileQuery.matches) {
      this.snav.close();
    }
  }
}
