<div class="navbar-container">
  <mat-sidenav-container class="navbar-sidenav-container">
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <div class="navbar-brand" [routerLink]="['/']" (click)="onMenuItemClick(false)" [matTooltip]="'Home' | translate">
        <img src="assets/images/logo-white.png" />
      </div>
      <mat-nav-list>
        <a mat-list-item class="list-item" [routerLink]="['/']" [class.active]="currentRoute == '/'"
          (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> dashboard </mat-icon>
          {{ "Dashboard" | translate }}
        </a>
        <a mat-list-item class="list-item" [routerLink]="['/clients']"
          [class.active]="currentRoute == '/clients' || currentRoute.indexOf('client') !== -1"
          (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> people_outline </mat-icon>
          {{ "Clients" | translate }}
        </a>
        <a mat-list-item class="list-item" [routerLink]="['/reports']" [class.active]="currentRoute == '/reports'"
          (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> insights </mat-icon>
          {{ "Reports" | translate }}
        </a>
        <a mat-list-item class="list-item" [routerLink]="['/notifications']"
          [class.active]="currentRoute == '/notifications'" (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> notifications </mat-icon>
          {{ "Notifications" | translate }}
        </a>
        <a mat-list-item class="list-item" [routerLink]="['/resources']" [class.active]="currentRoute == '/resources'"
          (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> pageview </mat-icon>
          {{ "User Guide" | translate }}
        </a>
        <a mat-list-item class="list-item" [routerLink]="['/users']" [class.active]="currentRoute == '/users'"
          *ngIf="isAdmin || isSupervisor || isCaseManager" (click)="onMenuItemClick(false)">
          <mat-icon mat-list-icon> supervisor_account </mat-icon>
          {{ "Users" | translate }}
        </a>
        <a mat-list-item class="list-item"
          *ngIf="permissions && (permissions['tiles'] || permissions['score-reports']|| permissions['rules']|| permissions['evaluation']|| permissions['procedures'])"
          (click)="showConfSubSubMenu = !showConfSubSubMenu">
          <mat-icon mat-list-icon>apps</mat-icon>
          {{ "Setup" | translate }}
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showConfSubSubMenu}">expand_more</mat-icon>
        </a>
        <div class="submenu" [ngClass]="{'expanded' : showConfSubSubMenu}">
          <a mat-list-item class="list-item" [routerLink]="['/tiles']" [class.active]="currentRoute == '/tiles'"
            *ngIf="permissions && permissions['tiles']" (click)="onSubMenuItemClick()">
            <mat-icon mat-list-icon>
              library_books
            </mat-icon>
            {{'Forms Library' | translate}}
          </a>
          <a mat-list-item class="list-item" [routerLink]="['/score-reports']"
            [class.active]="currentRoute == '/score-reports'" (click)="onSubMenuItemClick()"
            *ngIf="permissions && permissions['score-reports']">
            <mat-icon mat-list-icon>
              score
            </mat-icon>
            {{'Score Reports' | translate}}
          </a>
          <a mat-list-item class="list-item" [routerLink]="['/rules']" (click)="onSubMenuItemClick()"
            [class.active]="currentRoute == '/rules'" *ngIf="permissions && permissions['rules']">
            <mat-icon mat-list-icon>
              list_alt
            </mat-icon>
            {{'Rules' | translate}}
          </a>
          <a mat-list-item class="list-item" [routerLink]="['/evaluation']"
            [class.active]="currentRoute == '/evaluation'" (click)="onSubMenuItemClick()"
            *ngIf="permissions && permissions['evaluation']">
            <mat-icon mat-list-icon>
              trending_up
            </mat-icon>
            {{'Evaluations' | translate}}
          </a>
          <a mat-list-item class="list-item" [routerLink]="['/procedures']"
            [class.active]="currentRoute == '/procedures'" (click)="onSubMenuItemClick()"
            *ngIf="permissions && permissions['procedures']">
            <mat-icon mat-list-icon>
              check_box
            </mat-icon>
            {{'Procedures' | translate}}
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <mat-toolbar class="navbar">
        <button mat-icon-button class="menu-mobile" (click)="snav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <div fxLayout="row" fxLayout.lt-md="column" style="width: 100%;" fxLayoutAlign="space-between">
          <div fxFlex class="title" fxLayoutAlign="start center">{{ "WRNA V7 Case Manager Toolbox" | translate }}</div>
          <!-- <span class="navbar-spacer"></span> -->
          <div fxGrow="0" fxFlex fxLayoutAlign="end center" fxLayout="row" fxLayoutGap="0.5vw">
            <span fxFlex class="date">{{ now | date : "fullDate" }}</span>
            <div fxFlex fxGrow="0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5vw">
              <div fxFlex style="border-right: 1px solid; line-height: initial;">
                <mat-icon (click)="gotoNotifications()" class="notifications">notifications<div
                    *ngIf="showNotificationUnRead" class="unread"></div>
                </mat-icon>
              </div>
              <div style="position: relative;" *ngIf="currentNotification">
                <div class="notification-drawer">
                  <mat-icon class="close" (click)="closeNotification()">close</mat-icon>
                  <div class="body">
                    {{currentNotification}}
                  </div>
                </div>
              </div>
              <img fxFlex [src]="pictureLink || 'assets/images/profile.png'" class="profile-image user-menu-icon"
                onerror="this.src='assets/images/profile.png'" [matMenuTriggerFor]="userMenu" />
              <mat-menu #userMenu="matMenu" class="user-menu" xPosition="before" yPosition="above"
                [overlapTrigger]="false">
                <a mat-menu-item (click)="openEditProfile()">
                  <mat-icon>person</mat-icon>
                  <span>{{ "Account" | translate }}</span>
                </a>
                <a mat-menu-item (click)="logout()">
                  <mat-icon>exit_to_app</mat-icon>
                  <span>{{ "Log out" | translate }}</span>
                </a>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-toolbar>
      <app-loading-screen></app-loading-screen>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<audio #notification src="assets/files/notification.mp3" preload="auto" autostart="0"></audio>